type APMRUMAgentConfig = {
  active: boolean;
  serviceName: string;
  serviceUrl: string;
}

export type Config = {
  api: string;
  caseManagementUrl: string;
  analyticsUrl: string;
  authentication: {
    audience: string;
    authority: string;
    clientId: string;
    postLogoutRedirectUri: string;
    redirectUri: string;
  };
  defaultPageSize: number;
  serviceHookUriProtocols: string[];
  authEndPointUriProtocols: string[];
  apm: APMRUMAgentConfig;
  navigation: any[];
}

const config = {
  authentication: {},
} as Config;

function lowerCaseFirstLetter([first, ...rest]: string): string {
  return [first.toLowerCase(), ...rest].join('');
}

function mapKeys(obj: any, map: (key: string) => string): any {

  if (obj instanceof Array) {
    return obj.map(x => mapKeys(x, map));
  }

  if(!(obj instanceof Object)){
    return obj;
  }

  const keys = Object.keys(obj);

  return keys.reduce(
    (acc, key) => {
      const val = obj[key];
      return {
        ...acc,
        [map(key)]: typeof (val) === 'object' ? mapKeys(val, map) : val,
      };
    },
    {} as any,
  );
}

export const configLoading = Promise.all([
  fetch('js/config.json'),
  fetch('js/config-env.json')
])
  .then(([baseResponse, envResponse]) => Promise.all([baseResponse.json(), envResponse.json()]))
  .then(([baseJson, envJson]) => ({...baseJson, ...envJson}))
  .then((json) => mapKeys(json, lowerCaseFirstLetter))
  .then((json) => {
    const hookProtocols = (typeof json.serviceHookUriProtocols === 'string')
      ? json.serviceHookUriProtocols.split(',')
      : ['http'];
    const authProtocols = (typeof json.authEndPointUriProtocols === 'string')
      ? json.authEndPointUriProtocols.split(',')
      : ['http'];

    return Object.assign(
      config,
      {
        ...json,
        serviceHookUriProtocols: hookProtocols,
        authEndPointUriProtocols: authProtocols,
      },
    );
  }) as Promise<Config>;

export default config;
