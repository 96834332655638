import AxiosService from '@/services/axios-service';
import {
  RuleUpsertModel,
  Rule,
  RuleList,
} from '@/models/library-maintenance.d';
import { PaginationOptions } from '@/models/hal.d';
import EntityService from '@/services/entity-service';
import uriTemplate from 'url-template';
import config from '@/config';
import { CancelToken } from 'axios';

export default class RuleService {
  defaultPagenationOptions: PaginationOptions = {
    page: 1,
    size: config.defaultPageSize,
  };

  axiosService: AxiosService;

  entityService: EntityService;

  constructor(injectedAxios: AxiosService, injectedEntityService: EntityService) {
    this.axiosService = injectedAxios;
    this.entityService = injectedEntityService;
  }

  async readSingle(
    libraryId: string,
    ruleId: string,
    cancelToken?: CancelToken
  ): Promise<Rule> {
    const uri = `${config.api}/libraries/${libraryId}/rules/${ruleId}`;

    const rule = await this.axiosService.get(uri, { cancelToken });

    return rule as Rule;
  }

  async listRules(
    libraryId: string,
    options = this.defaultPagenationOptions,
    filter?: string,
    cancelToken?: CancelToken
  ): Promise<RuleList> {
    const { defaultPageSize, api } = config;
    const template = uriTemplate.parse(`${api}/libraries/${libraryId}/rules/{?page,size,sort*,filter}`);

    const uri = template.expand({
      ...{ page: 1, size: defaultPageSize, sort: [] },
      ...options,
      filter: filter && filter.length > 0 ? filter : undefined,
    });

    const list = await this.axiosService.get(uri, { cancelToken });

    return list as RuleList;
  }

  createRule(libraryId: string, rule: RuleUpsertModel): Promise<Rule> {
    const uri = `${config.api}/libraries/${libraryId}/rules`;

    return this.axiosService.post(uri, rule);
  }

  updateRule(
    libraryId: string,
    rule: RuleUpsertModel,
    ruleId?: string,
  ): Promise<Rule> {
    const uri = `${config.api}/libraries/${libraryId}/rules/${ruleId}`;

    return this.axiosService.put(uri, rule);
  }

  async setEnabledState(
    libraryId: string,
    ruleId: string,
    enable: boolean
  ): Promise<any> {
    if (!libraryId) return Promise.reject(new Error('No library id specified.'));
    if (!ruleId) return Promise.reject(new Error('No rule id specified.'));

    const reference = `libraries/${libraryId}/rules/${ruleId}`;

    return await this.entityService.enableDisable(reference, enable);
  }

  async deleteRule(libraryId: string, ruleId: string): Promise<any> {
    if (!libraryId) return Promise.reject(new Error('No library id specified.'));
    if (!ruleId) return Promise.reject(new Error('No rule id specified.'));

    const uri = `${config.api}/libraries/${libraryId}/rules/${ruleId}`;
    return await this.axiosService.delete(uri);
  }
}
