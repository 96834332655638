import {
  Library,
  LibraryList,
  LibraryUpdateModel,
  LibraryCreateModel,
} from '@/models/library-maintenance.d';
import uriTemplate from 'url-template';
import config from '@/config';
import { PaginationOptions } from '@/models/hal.d';

import { CancelToken } from 'axios';
import EntityService from './entity-service';
import AxiosService from './axios-service';
import { AxiosOptions } from '@/models/axios-service';
import { LibraryImportMessage } from '@/models/library-import';

export default class LibraryService {
  private defaultPagenationOptions: PaginationOptions = {
    page: 1,
    size: config.defaultPageSize,
  };

  axiosService: AxiosService;

  entityService: EntityService;

  constructor(injectedAxios: AxiosService, injectedEntityService: EntityService) {
    this.axiosService = injectedAxios;
    this.entityService = injectedEntityService;
  }

  async createLibrary(library: LibraryCreateModel): Promise<Library> {
    const uri = `${config.api}/libraries`;

    const newLibrary = await this.axiosService.post(uri, library);

    return newLibrary as Library;
  }

  async readSingle(
    libraryId: string,
    cancelToken?: CancelToken,
  ): Promise<Library> {
    const uri = `${config.api}/libraries/${libraryId}`;

    const library = await this.axiosService.get(uri, { cancelToken });

    return library as Library;
  }

  async listLibraries(
    options = this.defaultPagenationOptions,
    filter?: string,
    cancelToken?: CancelToken
  ): Promise<LibraryList> {
    const { defaultPageSize, api } = config;
    const template = uriTemplate.parse(`${api}/libraries/{?page,size,sort*,filter}`);

    const uri = template.expand({
      ...{ page: 1, size: defaultPageSize, sort: [] },
      ...options,
      filter: filter && filter.length ? filter : undefined,
    });

    const list = await this.axiosService.get(uri, { cancelToken });

    return list as LibraryList;
  }

  async updateLibrary(libraryId: string, library: LibraryUpdateModel): Promise<Library> {
    const uri = `${config.api}/libraries/${libraryId}`;

    const updatedLibrary = await this.axiosService.put(uri, library);

    return updatedLibrary as Library;
  }

  async deleteLibrary(libraryId: string | undefined): Promise<any> {
    if (!libraryId) return Promise.reject(new Error('No library id specified.'));

    const uri = `${config.api}/libraries/${libraryId}`;
    return await this.axiosService.delete(uri);
  }

  async setEnabledState(libraryId: string | undefined, enable: boolean): Promise<any> {
    if (!libraryId) return Promise.reject(new Error('No library id specified.'));

    const reference = `libraries/${libraryId}`;
    return await this.entityService.enableDisable(reference, enable);
  }

  async getExport(libraryId: string | undefined): Promise<{fileContents: any; fileName: string }> {
    if (!libraryId) return Promise.reject(new Error('No library id specified.'));

    const uri = `${config.api}/libraries/${libraryId}/export`;
    return await this.axiosService.getFile(uri);
  }

  async uploadLibrary(file: File, password: string): Promise<LibraryImportMessage[]> {
    if (!file) return Promise.reject(new Error('No file selected.'));

    const options = {
      headers: {
        'Content-Type': 'multipart/form-data' //tells axios to pass on the file.
      }
    } as AxiosOptions

    const formData = new FormData();
    formData.append("password", password);
    formData.append("importFile", file);

    const uri = `${config.api}/libraries/import`;
    const result = await this.axiosService.post(
      uri,
      formData,
      options
    );
    return result as LibraryImportMessage[]
  }
}
