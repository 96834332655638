import Vue from 'vue';
import VueRouter, { Route, RawLocation } from 'vue-router';
import { configLoading } from '@/config';
import routes from './routes';
import AuthService from '../services/auth-service';

Vue.use(VueRouter);

let previousRoute: Route | null = null;

async function buildRouter(): Promise<VueRouter> {
  const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: await routes,
  });

  let authService!: AuthService;
  let configLoaded = false;

  router.beforeEach(async (to, from, next) => {
    if (!configLoaded) {
      await configLoading;
      authService = new AuthService();
      configLoaded = true;
    }

    const routeIsSecure = to.matched.some((record) => record.meta.isSecure);

    if (!routeIsSecure) {
      next();
      return;
    }

    const isLoggedIn = await authService.isLoggedIn();

    if (isLoggedIn) {
      next();
      return;
    }

    await authService.login(to.fullPath);
  });

  router.afterEach((to, from) => {
    if (from.name) {
      previousRoute = from;
    }
  });

  return router;
}

const router: Promise<VueRouter> = buildRouter();

export async function backOr(route: RawLocation) {
  if (previousRoute) {
    (await router).back();
  } else {
    (await router).push(route);
  }
}
export default router;
