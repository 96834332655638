import { configLoading } from '@/config';

import LibraryService from './library-service';
import RuleService from './rule-service';
import AxiosService from './axios-service';
import ServiceHookService from './service-hook-service';
import PolicyService from './policy-service';
import EntityService from './entity-service';
import AuthService from './auth-service';
import ScreenResultsService from './screen-results-service';
import TemplateService from './template-service';
import TemplateOperationService from './template-operation-service';

const services: any = {};

export const servicesLoading = configLoading.then(() => {
  const authService = new AuthService();
  const axiosService = new AxiosService(authService);
  const entityService = new EntityService(axiosService);

  return Object.assign(services, {
    AuthService: authService,
    LibraryService: new LibraryService(axiosService, entityService),
    RuleService: new RuleService(axiosService, entityService),
    ServiceHookService: new ServiceHookService(axiosService),
    PolicyService: new PolicyService(axiosService),
    ScreenResultsService: new ScreenResultsService(axiosService),
    TemplateService: new TemplateService(axiosService),
    TemplateOperationService: new TemplateOperationService(axiosService)
  });
});

export default services;
