/* eslint-disable @typescript-eslint/camelcase */
import {
  UserManager,
  WebStorageStateStore,
  User,
  UserManagerSettings,
} from 'oidc-client';
import config from '@/config';

export type AuthUser = User &
{
  Permissions: string[];
}

export default class AuthService {
  private userManager: UserManager;

  constructor() {
    const AUTH0_DOMAIN = `${config.authentication.authority}`;

    const settings: UserManagerSettings = {
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      authority: AUTH0_DOMAIN,
      client_id: `${config.authentication.clientId}`,
      redirect_uri: `${config.authentication.redirectUri}`,
      response_type: 'id_token token',
      scope: 'openid profile',
      post_logout_redirect_uri: `${config.authentication.postLogoutRedirectUri}`,
      filterProtocolClaims: true,
      metadata: {
        issuer: `${AUTH0_DOMAIN}/`,
        authorization_endpoint: `${AUTH0_DOMAIN}/authorize`,
        userinfo_endpoint: `${AUTH0_DOMAIN}/userinfo`,
        end_session_endpoint: `${AUTH0_DOMAIN}/v2/logout`,
        jwks_uri: `${AUTH0_DOMAIN}/.well-known/jwks.json`,
      },
    };

    this.userManager = new UserManager(settings);
  }

  public getUserPermissions(user: User | null): string[] {
    if (user && user.access_token){
      const splitToken = user.access_token.split(".");
      if (splitToken.length > 0) {
        return JSON.parse(atob(splitToken[1])).permissions as string[];
      }
    }
   return []
  }

  public getUser(): Promise<AuthUser | null> {
    return this.userManager.getUser()
      .then((user) => {
          if (!user) {
            return null;
          }
          return {...user, Permissions: this.getUserPermissions(user)} as AuthUser;
        }
      )
  }


  public async getToken(): Promise<string | undefined> {
    const user = await this.userManager.getUser();

    return user?.access_token;
  }

  public login(redirect: string): Promise<void> {
    return this.userManager.signinRedirect({
      redirect_uri: `${config.authentication.redirectUri}?redirect=${redirect}`,
      extraQueryParams: {
        audience: config.authentication.audience,
      },
    });
  }

  public async logout(): Promise<void> {
    if (await this.isLoggedIn()) {
      return this.userManager.signoutRedirect({
        extraQueryParams: {
          returnTo: this.userManager.settings.post_logout_redirect_uri,
          client_id: this.userManager.settings.client_id,
        },
      });
    }
    return Promise.resolve();
  }

  public async isLoggedIn(): Promise<boolean> {
    const user = await this.getUser();

    if (user != null){
      return (!this.IsExpired(user))
    }

    return false;
  }

  public IsExpired(user: User): boolean {
      const userExpiryDate  = user['expires_at'] ?? 0;
      const nowDate = Math.round((new Date()).getTime() / 1000);
      if (userExpiryDate > nowDate) {
        return false;
      }
      return true;
  }
}
