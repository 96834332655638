import AxiosService from '@/services/axios-service';
import { Policy, PolicyList, PolicyUpdateModel } from '@/models/library-maintenance.d';
import { PaginationOptions } from '@/models/hal.d';
import config from '@/config';

import { pagedUrl } from './helpers';
import { CancelToken } from 'axios';

const defaultPaginationOptions: PaginationOptions = {
  page: 1,
  size: config.defaultPageSize,
};

export default class PolicyService {
  axiosService: AxiosService;

  constructor(injectedAxios: AxiosService) {
    this.axiosService = injectedAxios;
  }

  async createPolicy(policyCreateModel: PolicyUpdateModel): Promise<Policy> {
    const uri = `${config.api}/policies`;

    const result = await this.axiosService.post(uri, policyCreateModel);

    return result as Policy;
  }

  async readSingle(policyId: string): Promise<Policy> {
    const uri = `${config.api}/policies/${policyId}?include=all`;

    const policy = await this.axiosService.get(uri);

    return policy as Policy;
  }

  async listPolicies(
    options = defaultPaginationOptions,
    cancelToken?: CancelToken
  ): Promise<PolicyList> {
    const uri = pagedUrl(`${config.api}/policies`, options);

    const list = await this.axiosService.get(uri, { cancelToken });

    return list as PolicyList;
  }

  async updatePolicy(policyId: string, policy: PolicyUpdateModel): Promise<Policy> {
    const uri = `${config.api}/policies/${policyId}?include=all`;

    const updatedPolicy = await this.axiosService.put(uri, policy);

    return updatedPolicy as Policy;
  }

  async deletePolicy(policyId: string | undefined): Promise<any> {
    if (!policyId) return Promise.reject(new Error('No policy id specified.'));

    const uri = `${config.api}/policies/${policyId}`;

    return await this.axiosService.delete(uri);
  }

  async setEnabledState(policyId: string | undefined, enable: boolean): Promise<any> {
    if (!policyId) return Promise.reject(new Error('No policy id specified.'));

    const uri = `${config.api}/policies/${policyId}/${enable ? 'enable' : 'disable'}`;

    return await this.axiosService.put(uri, null);
  }
}
